<template>
    <!-- The Modal -->
    <div class="modal fade" id="myEditProcessModal">
        <div class="modal-dialog modal-lg">
            <div v-if="loading" class="loader-body">
                <div class="loader"></div>
            </div>

            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{ type == 'create' ? 'Create' : 'Edit' }} Process</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">

                    <div  v-if="item">
                        <div class="row pt-3">
                            <div class="col-12">
                                <h5>
                                    Configuration
                                </h5>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div v-if="type != 'bulk'" class="col-sm-4 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Inbox Enhanced Session Behaviour(s) <strong class="text-danger">*</strong></label>
                                                <multiselect v-model="item.emailBehaviors" :options="options" :close-on-select="false" :multiple="true"></multiselect>
                                            </div>

                                    </div>

                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Session Duration in minutes <strong class="text-danger">*</strong></label>
                                            <input type="number"  v-model="item.time" class="form-control"  min="1" max="120">
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Status <strong class="text-danger">*</strong></label>
                                            <ejs-dropdownlist cssClass="e-outline" :dataSource='statuses' :mode='true' v-model="item.status" placeholder='Status'></ejs-dropdownlist>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group ">

                                            <div class="row">
                                            <div class="form-group mr-1 col-auto">
                                                <label>Start Date <strong class="text-danger">*</strong></label>
                                                <input type="date" v-model="item.from" class="form-control date" placeholder="Date"/>
                                            </div>
<!--                                            <div class="form-group mr-1 mt-4 p-1 col-auto">-->
<!--                                                <p>&#9866;</p>-->
<!--                                            </div>-->
                                            <div class="form-group mr-1 col-auto">
                                                <label>End Date <strong class="text-danger">*</strong></label>
                                                <input type="date" v-model="item.to" class="form-control date" placeholder="To"/>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr>
                                <div class='text-danger text-center'>Controls all the action for the session</div>
                                <hr>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-3">
<!--                        exclusion list-->
                        <div class="col-12">
                            <h5 class="mb-4">
                                Email Rules
                            </h5>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Enter a list to <span class="text-danger">Avoid</span> replying to<strong class="text-danger">*</strong></label>
                                        <b-form-tags input-id="tags-basic" v-model="item.exclusionList" placeholder="Enter here"></b-form-tags>
                                        <div class="form-text text-muted">Enter short name ex. ebay</div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Enter a list to <span class="text-danger">Delete</span> emails from<strong class="text-danger">*</strong></label>
                                        <b-form-tags input-id="tags-basic" v-model="item.deleteList" placeholder="Enter here"></b-form-tags>
                                        <div class="form-text text-muted">Enter short name ex. ebay</div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Sender Email <strong class="text-danger">*</strong></label>
                                        <input type="text" v-model="rule.sender_email" class="form-control" />
                                        <p class="text-danger" v-if="errors.rule.sender_email">{{ errors.rule.sender_email }}</p>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Behaviour(s)<strong class="text-danger">*</strong></label>
                                        <multiselect v-model="rule.behaviors" :options="options" :close-on-select="false" :multiple="true"></multiselect>
                                        <p class="text-danger" v-if="errors.rule.behaviors">{{ errors.rule.behaviors }}</p>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-2 mt-2 p-2" style="align-self: center">
                                    <button class="btn btn-sm btn-info" @click="addRule()"><i class="fa fa-plus" /></button>
                                </div>

                            </div>
                            <hr class="mt-2">
                            <div class='text-danger text-center'>Ensure to press the add button after input to add Email and Behaviour(s)</div>
                            <hr>
                        </div>

                        <div v-if="item" class="col-12">
                            <div class="mt-2" v-if="item.rules && item.rules.length"><strong>Rules</strong></div>
                            <div class="row" v-for="(rule, index) in item.rules" :key="index">
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Sender Email</label>
                                        <input type="text" v-model="rule.sender_email" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Behaviour(s)</label>
                                        <multiselect v-model="rule.behaviors" :options="options"  :close-on-select="false" :multiple="true"></multiselect>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-2 mt-2 p-2" style="align-self: center">
                                    <button class="btn btn-sm btn-danger" @click="removeRule(index)"><i class="fa fa-trash" /></button>
                                </div>
                            </div>
                        </div>


                    </div>
                    <hr>
                    <div class='text-danger text-center'>Input a email address to reply to</div>
                    <hr>
                    <div class="row mt-5">
                        <div class="col-12">
                            <h5>
                                Reply
                            </h5>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Email Address to reply to <strong class="text-danger">*</strong></label>
                                        <input type="text" v-model="reply.sender_email" class="form-control" />
                                        <p class="text-danger" v-if="errors.reply.sender_email">{{ errors.reply.sender_email }}</p>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-2 mt-2 p-2" style="align-self: center">
                                    <button class="btn btn-sm btn-info" @click="addReply()"><i class="fa fa-plus" /></button>
                                </div>
                            </div>

                        </div>

                        <div v-if="item" class="col-12">
                            <div v-if="item.replys && item.replys.length" class="mt-2"><strong>Reply</strong></div>
                            <div class="row" v-for="(reply, index) in item.replys" :key="index">

                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Sender Email</label>
                                        <input type="text" v-model="reply.sender_email" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-2 mt-2 p-2" style="align-self: center">
                                    <button class="btn btn-sm btn-danger" @click="removeReply(index)"><i class="fa fa-trash" /></button>
                                </div>
                            </div>
                        </div>
                    </div>





                    <div class="row mt-5">
                        <div class="col-12">
                            <h5>
                                Forwarding Address
                            </h5>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Sender Email <strong class="text-danger">*</strong></label>
                                        <input type="text" v-model="forwarding.sender_email" class="form-control" />
                                        <p class="text-danger" v-if="errors.forwarding.sender_email">{{ errors.forwarding.sender_email }}</p>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Forwarding Email<strong class="text-danger">*</strong></label>
                                        <input type="text" v-model="forwarding.email" class="form-control" />
                                        <p class="text-danger" v-if="errors.forwarding.email">{{ errors.forwarding.email }}</p>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-2 mt-2 p-2" style="align-self: center">
                                    <button class="btn btn-sm btn-info" @click="addForwarding()"><i class="fa fa-plus" /></button>
                                </div>
                            </div>
                            <hr class="mt-2">
                            <div class='text-danger text-center'>Ensure to press the add button after input to add Sender Email and Forwarding Email</div>
                            <hr>
                        </div>


                        <div v-if="item" class="col-12">
                            <div v-if="item.forwardings && item.forwardings.length" class="mt-2"><strong>Forwarding</strong></div>
                            <div class="row" v-for="(forwarding, index) in item.forwardings" :key="index">

                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Sender Email</label>
                                        <input type="text" v-model="forwarding.sender_email" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Forwarding Email</label>
                                        <input type="text" v-model="forwarding.email" class="form-control"/>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-2 mt-2 p-2" style="align-self: center">
                                    <button class="btn btn-sm btn-danger" @click="removeForwarding(index)"><i class="fa fa-trash" /></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-12">
                            <h5>
                                Junk Removal
                            </h5>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Email Address to remove from Junk <strong class="text-danger">*</strong></label>
                                        <input type="text" v-model="junk.sender_email" class="form-control" />
                                        <p class="text-danger" v-if="errors.junk.sender_email">{{ errors.junk.sender_email }}</p>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-2 mt-2 p-2" style="align-self: center">
                                    <button class="btn btn-sm btn-info" @click="addJunk()"><i class="fa fa-plus" /></button>
                                </div>
                            </div>
                            <hr class="mt-2">
                            <div class='text-danger text-center'>Ensure to press the add Junk</div>
                            <hr>
                        </div>

                        <div v-if="item" class="col-12">
                            <div v-if="item.junks && item.junks.length" class="mt-2"><strong>Junk</strong></div>
                            <div class="row" v-for="(junk, index) in item.junks" :key="index">

                                <div class="col-sm-4 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Sender Email</label>
                                        <input type="text" v-model="junk.sender_email" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-2 mt-2 p-2" style="align-self: center">
                                    <button class="btn btn-sm btn-danger" @click="removeJunk(index)"><i class="fa fa-trash" /></button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


                <div class="modal-footer">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group text-center">
                                <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal">
                                    <span class="glyphicon glyphicon-ok"></span> Cancel
                                </button>
                                <button type="button" class="btn btn-success btn-sm ml-3"
                                        @click.prevent="save(true)">
                                    <span class="glyphicon glyphicon-ok"></span> Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Vue from "vue";
import axios from "axios";
import swal from "sweetalert";

import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";


Vue.use(DropDownListPlugin);


export default {
    name: "EWProcessModal",
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        type: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            page: 1,
            count: 0,
            statuses: [
                "active",
                "disabled"
            ],
            isLoading: false,
            accounts: [],
            junk: { sender_email: ''},
            reply: { sender_email: ''},
            rule: { sender_email: '', behaviors: null},
            forwarding: {sender_email: '', email: ''},
            errors: { rule: { sender_email: null, behaviors: null},
                forwarding: {sender_email: null, email: null},
                junk: {sender_email: null},
                reply:{sender_email: null}},
            options: ['reply', 'delete', 'forward','junk'],
            data: [],
            preloader: false,
            max: 25,
            loading: false
        }
    },
    methods: {
        async onInput(e) {
            this.isLoading = true;

            let request = {search: e};
            axios.get(`${this.$root.serverUrl}/admin/email-warmer/accounts`, {params: request}).then((resp) => {
                if(resp.data){
                    this.accounts = resp.data.data;
                }
            }).catch((err) => {
                console.log(err);
                swal({title: 'Oops', text: err.message, icon: 'error'})
            }).finally(() => {
                this.isLoading = false
            });
        },
        addForwarding() {
            if(this.item.forwardings == undefined)this.item.forwardings = []
            if (!this.forwarding.sender_email) this.errors.forwarding.sender_email = "Please enter a valid email";
            else this.errors.forwarding.sender_email = null;
            if (!this.forwarding.email.length) this.errors.forwarding.email = "Please enter a valid email";
            else this.errors.forwarding.email = null;

            let error = false;
            for (let err in this.errors.forwarding) {
                if (this.errors.forwarding[err]) {
                    error = true
                    break;
                }
            }

            if (error) return;

            this.item.forwardings.push(this.forwarding)

            this.forwarding = {
                email: '',
                sender_email: ''
            }
        },
        addRule() {
            if(  this.item.rules == undefined)this.item.rules = []
            if (!this.rule.sender_email) this.errors.rule.sender_email = "Please enter a valid email";
            else this.errors.rule.sender_email = null;
            if (!this.rule.behaviors.length) this.errors.rule.behaviors = "Please add at least 1 behaviour";
            else this.errors.rule.behaviors = null;

            let error = false;
            for (let err in this.errors.rule) {
                if (this.errors.rule[err]) {
                    error = true
                    break;
                }
            }

            if (error) return;

            this.item.rules.push(this.rule)
            this.rule = {
                email: '',
                behaviors: []
            }
        },
        addJunk() {
            if(  this.item.junks == undefined) this.item.junks = []
            if (!this.junk.sender_email) this.errors.junk.sender_email = "Please enter a valid email";
            else this.errors.junk.sender_email = null;


            let error = false;
            for (let err in this.errors.junk) {
                if (this.errors.junk[err]) {
                    error = true
                    break;
                }
            }
            if (error) return;

            this.item.junks.push(this.junk)
            this.junk = {
                sender_email: '',
                behaviors: []
            }
        },
        addReply() {
            if(  this.item.replys == undefined) this.item.replys = []
            if (!this.reply.sender_email) this.errors.reply.sender_email = "Please enter a valid email";
            else this.errors.reply.sender_email = null;
            let error = false;
            for (let err in this.errors.reply) {
                if (this.errors.reply[err]) {
                    error = true
                    break;
                }
            }
            if (error) return;

            this.item.replys.push(this.reply)
            this.reply = {
                sender_email: '',
                behaviors: []
            }
        },
        removeReply(index){
            this.item.replys.splice(index, 1)
            this.$forceUpdate()
        },
        removeJunk(index){
            this.item.junks.splice(index, 1)
            this.$forceUpdate()
        },
        removeRule(index){
            this.item.rules.splice(index, 1)
            this.$forceUpdate()
        },
        removeForwarding(index) {
            this.item.forwardings.splice(index, 1)
            this.$forceUpdate()
        },
        save() {
            this.loading = true;
            let request = {processes: [], args: this.item};

            if (this.item.processes) request.processes = this.item.processes
            if(this.item.emailBehaviors == undefined || this.item.emailBehaviors == null ||  this.item.emailBehaviors.length == 0  ){
                swal({ title: "Error", text: 'Inbox EnhancedSession Behaviour required', icon: "error" });
            }else{



            axios.put(`${this.$root.serverUrl}/admin/email-warmer/processes`, request).then((resp) => {
                if (!resp.data.success) {
                    this.errormsg = resp.data.message || "An unexpected error occurred";
                    swal({ title: "Error", text: this.errormsg, icon: "error" });
                    return
                }

                swal({
                    title: "Success",
                    text: "Update Success fully",
                    icon: "success",
                });

                window.$('#myEditProcessModal').modal('hide');

                this.$emit('refresh')
            }).catch((err) => {
                this.errormsg = err.message || "An unexpected error occurred";
                swal({ title: "Error", text: this.errormsg, icon: "error" });
            }).finally(() => {
                this.loading = false;
            });
            }
        }
    }
};
</script>

<style scoped>
.modal-dialog {
    max-width: 90%;
    height: 70vh;
    display: flex;
    max-width: 900px;
}
.modal-header {
    background: #0d0d48;
}
.modal-body {
    overflow-y: scroll;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}
.loader-body {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9999;
    background: #000000ad;
}

.loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@import '../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css';
</style>
